import React from 'react'
import renderBlok from '@renderBlok'
import renderBloks from '@renderBloks'
import SbEditable from 'storyblok-react'
import { makeStyles } from '@material-ui/styles'
import { Box, Grid } from '@material-ui/core'
import { H3, H5 } from '@system'
import PageContainer from '@system/page-container'
import ContentContainer from '@system/content-container'

const useStyles = makeStyles((theme) => ({
  lifecycleModuleRoot: ({ bgColor }) => ({
    backgroundColor: bgColor?.color && `${bgColor.color} !important`,
  }),
  mainContainer: {
    '& .icon-wrapper:first-child .phase:before, & .icon-wrapper:last-child .phase:after':
      {
        background: 'none',
      },
  },
  image: ({ hasMobileImage }) => ({
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      display: !hasMobileImage && 'none',
    },
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  }),
  phase: {
    color: theme.palette.text.tertiary,
    fontWeight: theme.typography.fontWeightMedium,
    textAlign: 'center',
    display: 'block',
  },
  title: {
    color: theme.palette.secondary.main,
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: '2rem 0',
    },
    [theme.breakpoints.up('sm')]: {
      padding: '3rem 0',
    },
    display: 'block',
  },
  iconWrapper: {
    width: '20px',
  },
  ctaContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '2rem',
    '& a': {
      margin: '0 1rem',
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      '& a': {
        margin: '0.5rem 0',
      },
    },
  },
}))

const LifecycleModule = ({ blok }) => {
  const { bgColor, cta, title, image, phases } = blok
  const hasMobileImage = image?.[0]?.mobileImage?.id !== null
  const classes = useStyles({
    bgColor,
    classes: { iconWrapper: 'icon-wrapper' },
    hasMobileImage,
  })

  return (
    <SbEditable content={blok}>
      <PageContainer className={classes.lifecycleModuleRoot}>
        <ContentContainer>
          {!!title && <H3 className={classes.title}>{title}</H3>}
          {!!image &&
            image.map((blok) => (
              <Box key={blok._uid}>
                {renderBlok({
                  ...blok,
                  className: classes.image,
                })}
              </Box>
            ))}
          <Grid direction="row" container className={classes.mainContainer}>
            {phases.map((blok) => (
              <Grid item xs={3} key={blok._uid} className={classes.iconWrapper}>
                <H5 className={classes.phase}>{renderBlok(blok)}</H5>
              </Grid>
            ))}
          </Grid>
          <Box className={classes.ctaContainer}>{renderBloks(cta)}</Box>
        </ContentContainer>
      </PageContainer>
    </SbEditable>
  )
}
export default LifecycleModule
